import Head from "next/head"
import { Stack, StackProps } from "@ui/stack"
import styled from "styled-components"
import css from "@styled-system/css"
import { useCurrentAdmin } from "react-current-admin"
import { CurrentAdminBanner } from "@banners/current-admin"
import { VerifyEmailBanner } from "@banners/verify-email"
import { useCurrentUser } from "react-current-user"
import "@reach/menu-button/styles.css"

import { SidebarNavigation } from "./sidebar-nav"
import { MobileNav, MOBILE_NAV_HEIGHT } from "./mobile-nav"
import { ReactNode } from "react"

export type SidebarLayoutProps = {
  title: string
  disableVerifyAlert?: boolean
  children: ReactNode
} & StackProps

export const SidebarLayout: React.FC<SidebarLayoutProps> = ({ title, disableVerifyAlert, children }) => {
  const admin = useCurrentAdmin()
  const [user] = useCurrentUser()

  const showVerifyAlert = !disableVerifyAlert && user && !user.isConfirmed

  return (
    <>
      <Head>
        <title>{title} | HeartPayroll</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Body>
        <DesktopSidebar justifyContent="space-between">
          <SidebarNavigation />
        </DesktopSidebar>

        <Content>
          <MobileNav />
          <SidebarLayoutBlock as="main" paddingY="5">
            {admin && <CurrentAdminBanner {...admin} />}
            {showVerifyAlert && <VerifyEmailBanner />}
            {!showVerifyAlert && children}
          </SidebarLayoutBlock>
        </Content>
      </Body>
    </>
  )
}

SidebarLayout.displayName = "SidebarLayout"

const SIDEBAR_WIDTH = "200px"

const Body = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  ${css({
    main: {
      marginTop: [MOBILE_NAV_HEIGHT, "0px"],
    },
    backgroundColor: "primary.10",
  })}
`

const DesktopSidebar = styled(Stack)`
  ${css({
    display: ["none", "block"],
    position: "fixed",
    top: "0px",
    left: "0px",
    height: "100vh",
    width: SIDEBAR_WIDTH,
    backgroundColor: "white",
    borderRight: "1px solid",
    borderColor: "gray.border",
    flexShrink: 0,
  })}
`

const Content = styled(Stack).attrs({ direction: "vertical" })`
  ${css({
    gap: "7",
    justifyContent: "space-between",
    marginLeft: ["0px", SIDEBAR_WIDTH],
    minHeight: "100vh",
    width: "100%",
  })}
`

export const SidebarLayoutBlock = styled(Stack)`
  ${css({
    maxWidth: "page.max",
  })}
`

SidebarLayoutBlock.defaultProps = {
  paddingX: 5,
  gap: 6,
}
