import { Card as CardComponent } from "./card"
import { FullWidthContent } from "./full-width-content"

export type Card = typeof CardComponent & {
  FullWidthContent: typeof FullWidthContent
}

export const Card = CardComponent as Card

Card.FullWidthContent = FullWidthContent
