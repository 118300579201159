import { ReactNode } from "react"
import { Heading } from "@ui/heading"
import { Headline } from "@ui/headline"
import Stack from "@ui/stack"
import { BackLink } from "@layouts/sidebar"

export type PageHeaderProps = {
  backlink?: {
    href: string
    text: ReactNode
  }
  heading: ReactNode
  headline?: ReactNode
}

export const PageHeader = ({ backlink, heading, headline }: PageHeaderProps) => {
  if (!headline && !backlink) {
    return (
      <Heading as="h1" level="1">
        {heading}
      </Heading>
    )
  }

  return (
    <Stack gap="5">
      {backlink && <BackLink href={backlink.href}>{backlink.text}</BackLink>}

      <Heading as="h1" level="1">
        {heading}
      </Heading>
      {headline && <Headline>{headline}</Headline>}
    </Stack>
  )
}
