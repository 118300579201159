import { Heading } from "@ui/heading"
import { Stack, StackProps } from "@ui/stack"
import { ReactNode } from "react"

const CARD_PADDING = "4"

export type CardProps = StackProps &
  JSX.IntrinsicElements["form"] & {
    as?: any
    heading?: ReactNode
    description?: ReactNode
    children: ReactNode
    variant?: "splash"
  }

export const Card = ({ heading, description, children, variant, ...props }: CardProps) => {
  const borderColor = variant === "splash" ? "primary.200" : "gray.200"
  const bg = variant === "splash" ? "primary.100" : "white"
  return (
    <Stack
      p={CARD_PADDING}
      borderRadius="medium"
      border="1px solid"
      borderColor={borderColor}
      bg={bg}
      gap="5"
      {...props}
    >
      <CardHeader heading={heading} description={description} />

      {children}
    </Stack>
  )
}

Card.displayName = "Card"

type CardHeaderProps = {
  heading?: ReactNode
  description?: ReactNode
}

const CardHeader = ({ heading, description }: CardHeaderProps) => {
  if (!heading && !description) {
    return null
  }
  return (
    <Stack gap="4">
      {heading && (
        <Heading as="h2" level="2">
          {heading}
        </Heading>
      )}
      {description && <div>{description}</div>}
    </Stack>
  )
}
