import css from "@styled-system/css"
import styled from "styled-components"
import { color, ColorProps, variant } from "styled-system"
import theme from "@ui/theme"

export type HeadlineProps = ColorProps & { variant?: "marketing" }

export const Headline = styled.p<HeadlineProps>`
  ${css({
    fontSize: 3,
    em: {
      textDecoration: "underline",
      textDecorationColor: theme.colors.primary[300],
      fontStyle: "normal",
    },
  })}
  ${color}
  ${variant({
    variants: {
      marketing: {
        fontSize: [3, 5],
      },
    },
  })}
`

Headline.displayName = "Headline"
