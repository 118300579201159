import styled, { keyframes } from "styled-components"
import css from "@styled-system/css"
import { variant } from "styled-system"
import logo from "../../../public/images/heartpayroll-logo.svg"
import { Stack } from "@ui/stack"
import Image from "next/image"
import Link from "next/link"
import { PageBlock } from "@ui/page-block"
import { Menu, MenuButton, MenuList, MenuLink, MenuItem } from "@reach/menu-button"
import "@reach/menu-button/styles.css"
import { logout } from "@app/logout"
import { Button } from "@ui/button"
import VisuallyHidden from "@reach/visually-hidden"
import { homepagePath, loginPath, signupPath, userEditPath } from "@app/routes"
import { useCurrentUser } from "react-current-user"
import { theme } from "@ui/theme"
import { useEffect } from "react"
import { useRouter } from "next/router"

import { TopGroup, BottomGroup } from "./nav-groups"
import { SettingsIcon } from "@icons/settings"
import { LogoutIcon } from "@icons/logout"
import { UsersIcon } from "@icons/users"
import { InvoiceIcon } from "@icons/invoice"
import { MenuIcon } from "@icons/menu"
import { CloseIcon } from "@icons/close"
import { HouseIcon } from "@icons/house"
import { isClient } from "is-client"

export const MOBILE_NAV_HEIGHT = logo.height / 3 + theme.space[3] * 2 + "px"

const slidedown = keyframes`
  0% {
    transform: translateY(-50vh);
  }
  100% {
    transform: translateY(0);
  }
`

const MobileMenuList = styled(MenuList)`
  animation: ${slidedown} 0.18s ease;
  ${css({
    display: ["block", "none"],
    position: "fixed",
    width: "100%",
    zIndex: "990",
    left: "0px",
    padding: "0px",
    border: "0px",
    backgroundColor: "gray.space",

    "[data-reach-menu-item]": {
      color: "gray.text",
      padding: "0px",
      paddingX: 6,
      paddingY: 4,
      fontSize: 2,
      backgroundColor: "white",
      i: {
        width: "32px",
        mr: 2,
      },
    },

    "[data-reach-menu-item][data-selected]": {
      textDecoration: "none",
      backgroundColor: "primary.100",
    },
  })}
  // Attempt fix for Mobile
  ${css({
    height: "100%",
    top: "0px",
    paddingTop: MOBILE_NAV_HEIGHT,
  })}
  height: -webkit-stretch;
`

export const MobileNav = styled((props) => {
  const [user] = useCurrentUser()

  return (
    <PageBlock as="nav" paddingY="3" borderBottom="1px solid" borderColor="gray.border" {...props}>
      <Stack direction="horizontal" justifyContent="space-between" alignItems="center">
        <Link href={homepagePath} passHref>
          <a>
            <Image priority alt="" {...logo} layout="fixed" height={logo.height / 3} width={logo.width / 3} />
            <VisuallyHidden>HeartPayroll homepage</VisuallyHidden>
          </a>
        </Link>

        {!user && (
          <Stack gap="3" direction="horizontal">
            <Link href={loginPath} passHref>
              <Button variant="ghost" id="nav-login">
                Login
              </Button>
            </Link>
            <Link href={signupPath} passHref>
              <Button rel="noreferrer noopener" id="nav-sign-up">
                Sign up
              </Button>
            </Link>
          </Stack>
        )}
        {user && (
          <Menu>
            {({ isExpanded }) => (
              <>
                <MenuButton as="button" id="main-menu">
                  {isExpanded ? (
                    <>
                      <CloseIcon />
                      <VisuallyHidden>Close menu</VisuallyHidden>
                    </>
                  ) : (
                    <>
                      <MenuIcon />
                      <VisuallyHidden>Open menu</VisuallyHidden>
                    </>
                  )}
                </MenuButton>
                <MobileDropdown isExpanded={isExpanded} user={user} />
              </>
            )}
          </Menu>
        )}
      </Stack>
    </PageBlock>
  )
})`
  ${css({
    position: "fixed",
    top: "0px",
    height: MOBILE_NAV_HEIGHT,
    width: "100%",
    zIndex: 1000,
    background: "white",
    display: ["block", "none"],
  })}
  [data-reach-menu-button] {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    ${css({
      color: "gray.heading",
      fontSize: "2",
    })}
  }
`

const MobileDropdown = ({ isExpanded, user }: any) => {
  useEffect(() => {
    document.body.style.overflow = isExpanded ? "hidden" : "auto"
  }, [isExpanded])

  return (
    <MobileMenuList className="slide-down">
      <Stack height="100%" justifyContent="space-between">
        <TopGroup>
          <Link href={"/"} passHref>
            <MobileLink id="mobile-nav-">
              <HouseIcon />
              Dashboard
            </MobileLink>
          </Link>

          <Link href={"/documents"} passHref>
            <MobileLink id="mobile-nav-documents">
              <InvoiceIcon />
              Documents
            </MobileLink>
          </Link>

          {/* Client links */}
          {isClient(user) && (
            <>
              <Link href={"/employees"} passHref>
                <MobileLink id="mobile-nav-employees">
                  <UsersIcon /> Employees
                </MobileLink>
              </Link>
            </>
          )}
          {/* Employee links */}
          {user?.type === "employee" && (
            <>
              <Link href={"/employers"} passHref>
                <MobileLink id="mobile-nav-employers">
                  <UsersIcon /> Employers
                </MobileLink>
              </Link>
            </>
          )}
        </TopGroup>

        <BottomGroup>
          <Link href={userEditPath} passHref>
            <MobileLink id="mobile-nav-settings">
              <SettingsIcon />
              Settings
            </MobileLink>
          </Link>
          <MenuItem id="mobile-nav-logout" onSelect={logout}>
            <LogoutIcon />
            Logout
          </MenuItem>
        </BottomGroup>
      </Stack>
    </MobileMenuList>
  )
}

MobileNav.displayName = "MobileNav"

const MobileLink = (props: any) => {
  const router = useRouter()

  let active = router.asPath === props.href

  if (props.href != "/") {
    active = router.asPath.startsWith(props.href)
  }

  return <StyledMenuLink {...props} active={active} />
}

const StyledMenuLink = styled(MenuLink)`
  ${variant({
    prop: "active",
    variants: {
      true: {
        borderLeft: "4px solid",
        borderLeftColor: "primary.300",
        "&:hover": {
          borderLeftColor: "primary.300",
        },
      },
      false: {
        borderLeft: "4px solid",
        borderLeftColor: "transparent",
        "&:hover": {
          borderLeftColor: "transparent",
        },
      },
    },
  })}
`
