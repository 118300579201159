import css from "@styled-system/css"
import Box from "@ui/box"
import styled from "styled-components"

export const FullWidthContent = styled(Box)`
  ${css({
    borderTop: "1px solid",
    borderTopColor: "gray.100",
    marginX: "-16px",
  })}
`
