import { BackIcon } from "@icons/back"
import css from "@styled-system/css"
import theme from "@ui/theme"
import Link from "next/link"
import styled from "styled-components"

export type BackLinkProps = {
  href: string
  id?: string
  children: React.ReactNode
  className?: string
}

export const BackLink = styled(({ id, href, children, className }: BackLinkProps) => {
  return (
    <Link href={href} passHref>
      <a id={id} className={className}>
        <BackIcon />
        {children}
      </a>
    </Link>
  )
})`
  ${css({
    fontSize: 2,
    fontWeight: "bold",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: theme.colors.primary[300],
      textDecorationThickness: "2px",
    },
    i: {
      mr: 2,
    },
  })}
`
