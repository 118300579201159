import Stack from "@ui/stack"
import styled from "styled-components"
import css from "@styled-system/css"

export const TopGroup = styled(Stack)`
  ${css({
    "> *": {
      borderBottom: "1px solid",
      borderBottomColor: "gray.100",
    },
  })}
`
export const BottomGroup = styled(Stack)`
  ${css({
    "> *": {
      borderTop: "1px solid",
      borderTopColor: "gray.100",
    },
  })}
`
