import styled from "styled-components"
import css from "@styled-system/css"
import logo from "../../../public/images/heartpayroll-logo.svg"
import { logout } from "@app/logout"
import { userEditPath, loginPath, signupPath } from "@app/routes"
import Image from "next/image"
import VisuallyHidden from "@reach/visually-hidden"
import Stack from "@ui/stack"
import Link from "next/link"
import { useCurrentUser } from "react-current-user"
import { variant } from "styled-system"
import { useRouter } from "next/router"
import { TopGroup, BottomGroup } from "./nav-groups"
import { HouseIcon } from "@icons/house"
import { InvoiceIcon } from "@icons/invoice"
import { UsersIcon } from "@icons/users"
import { SettingsIcon } from "@icons/settings"
import { LogoutIcon } from "@icons/logout"
import { EarnRewards } from "@app/rewards-prompt"
import { isClient } from "is-client"

export const SidebarNavigation = () => {
  const [user] = useCurrentUser()

  return (
    <Stack height="100%" justifyContent="space-between" backgroundColor="gray.space">
      <TopGroup>
        <Link href={"https://heartpayroll.com"} passHref>
          <LogoLink>
            <Image priority alt="" src={logo.src} height={logo.height / 3} width={logo.width / 3} />
            <VisuallyHidden>HeartPayroll homepage</VisuallyHidden>
          </LogoLink>
        </Link>

        <Link href={"/"} passHref>
          <SidebarLink id="nav-dashboard">
            <HouseIcon />
            Dashboard
          </SidebarLink>
        </Link>

        <Link href={"/documents"} passHref>
          <SidebarLink id="nav-documents">
            <InvoiceIcon />
            Documents
          </SidebarLink>
        </Link>

        {isClient(user) && (
          <>
            <Link href={"/employees"} passHref>
              <SidebarLink id="nav-employees">
                <UsersIcon />
                Employees
              </SidebarLink>
            </Link>
          </>
        )}

        {user?.type === "employee" && (
          <>
            <Link href={"/employers"} passHref>
              <SidebarLink id="nav-employers">
                <UsersIcon /> Employers
              </SidebarLink>
            </Link>
          </>
        )}
      </TopGroup>

      <BottomGroup>
        {user && (
          <>
            {isClient(user) && <EarnRewards />}
            <Link href={userEditPath} passHref>
              <SidebarLink id="nav-settings">
                <SettingsIcon />
                Settings
              </SidebarLink>
            </Link>
            <SidebarLink id="nav-logout" as="a" onClick={logout}>
              <LogoutIcon />
              Logout
            </SidebarLink>
          </>
        )}

        {!user && (
          <>
            <Link href={loginPath} passHref>
              <SidebarLink id="nav-login">Login</SidebarLink>
            </Link>
            <Link href={signupPath} passHref>
              <SidebarLink rel="noreferrer noopener" id="nav-sign-up">
                Sign up
              </SidebarLink>
            </Link>
          </>
        )}
      </BottomGroup>
    </Stack>
  )
}

const LogoLink = styled.a`
  ${css({
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingX: 4,
    paddingTop: 4,
    paddingBottom: 3,
  })}
`

const SidebarLink = (props: any) => {
  const router = useRouter()

  let active = router.asPath === props.href

  if (props.href != "/") {
    active = router.asPath.startsWith(props.href)
  }

  return <SidebarLinkStyle {...props} active={active} />
}

const SidebarLinkStyle = styled.a`
  ${css({
    backgroundColor: "white",
    paddingX: 4,
    paddingY: 4,
    textDecoration: "none",
    textDecorationThickness: "0px",
    borderLeft: "4px solid white",
    "&:hover": {
      backgroundColor: "primary.100",
      borderLeftColor: "primary.100",
    },
    i: {
      width: "24px",
      marginRight: 1,
    },
  })}
  ${variant({
    prop: "active",
    variants: {
      true: {
        borderLeftColor: "primary.300",
        "&:hover": {
          backgroundColor: "primary.100",
          borderLeftColor: "primary.300",
        },
      },
    },
  })}
`
